<template></template>
  
<script>
import { mapActions, mapGetters } from 'vuex';

export default
  {
    created() {
      this.fetchData();
    },
    beforeRouteUpdate(to, from, next) {
      this.fetchData();
      next();
    },
    methods:
    {
      ...mapActions(['getBusinessById', 'getBusinessProducts', 'getHotDeals']),
      fetchData() {
        if (this.params.tabID && this.params.TName) {
          this.params.TName = ''
        }
        this.getBusinessById({
          business_id: this.params.id,
          lang: this.$i18n.locale
        }).then(res => {
          let busName = this.business.translations[0].name;
          let show = undefined;

          if (this.business.profile_style) {
            if (this.business.profile_style.key == 'productWithPrices') {
              show = "Yes"
            } else {
              show = "No"
            }
          }
          localStorage.setItem('show', show || this.business.show_prices);
          if (this.business.categories.length > 0 && (this.params.tab == 3 || this.params.tabID == 'rel' || this.params.BName == 'products' || this.params.TName == 'products' || (this.params.productId && this.params.tabID == 'rel'))) {
            this.$router.replace({
              name: 'BusinessProducts',
              params: {
                locale: this.$i18n.locale,
                id: this.params.id,
                BName: busName.replace(/ /g, '_').toLowerCase(),
                tabID: 'rel',
                productId: this.params.productId,
                show: show || this.business.show_prices
              }
            })
          } else if (this.params.BName == 'reservation' || this.params.BName == 'checkin') {
            this.$router.push({
              name: 'BusinessReservations',
              params: {
                id: this.params.id,
                BName: this.params.BName,
              }
            })
          } else if (this.params.tabID == 'desc' || this.params.BName == 'info' || this.params.TName == 'info') {
            this.$router.replace({
              name: 'BusinessInfo',
              params: {
                locale: this.$i18n.locale,
                id: this.params.id,
                BName: busName.replace(/ /g, '_').toLowerCase(),
                // BName: 'info',
                TName: 'info',
                tabID: 'desc',
                show: show || this.business.show_prices
              }
            })
          } else if (this.params.tabID == 'rev' || this.params.BName == 'reviews' || this.params.TName == 'reviews') {
            this.$router.replace({
              name: 'BusinessInfo',
              params: {
                locale: this.$i18n.locale,
                id: this.params.id,
                BName: busName.replace(/ /g, '_').toLowerCase(),
                // BName: 'reviews',
                TName: 'reviews',
                tabID: 'rev',
                show: show || this.business.show_prices
              }
            })
          } else {
            this.$router.replace({
              name: 'BusinessInfo',
              params: {
                locale: this.$i18n.locale,
                id: this.params.id,
                BName: busName.replace(/ /g, '_').toLowerCase(),
                tabID: this.params.tabID || 'desc',
                TName: 'info',
                show: show || this.business.show_prices
              }
            })
          }
        }).catch(err => console.log(err));
      }
    },

    computed: {
      ...mapGetters(['business', 'businessProducts', 'busHotDeals', 'businessProducts']),
      params() {
        return this.$route.params;
      }
    }
  }
</script>



